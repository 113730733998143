<template>
  <el-main>
    <page-title>
      <template slot="btn-inner">
        <el-button @click="$router.push({ name: 'index' })">
          <i class="iconfont icon-fanhui" />返回
        </el-button>
      </template>
    </page-title>
    <div class="app-container">
      <el-row :gutter="20">
        <el-col :span="5" :xs="24">
          <el-card style="margin-bottom:20px;">
            <div style="height: 510px">
              <div slot="header" class="clearfix">
                <span style="font-size: 19px; font-weight: bold;">个人中心</span>
              </div>
              <el-row class="tac">
                <el-col :span="24">
                  <el-menu
                    default-active="1"
                    class="el-menu-vertical-demo"
                    style="text-align: right;font-size: 12px;"
                    @select="selectMenu"
                  >
                    <br>
                    <el-menu-item index="1">
                      <span slot="title" style="margin-right: 20px;">用户信息</span>
                    </el-menu-item>
                    <br>
                    <el-menu-item index="2">
                      <span slot="title" style="margin-right: 20px;">账号安全</span>
                    </el-menu-item>
                    <br>
                    <el-menu-item index="3">
                      <span slot="title" style="margin-right: 20px;">控件下载</span>
                    </el-menu-item>
                  </el-menu>
                </el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
        <el-col :span="19" :xs="24">
          <el-card>
            <div style="height: 510px">
              <ics-page-inner v-if="Number(defaultActiveIndex) === 1" title="用户信息">
                <template slot="btn-inner">
                  <div class="btn-inner">
                    <el-button v-if="isUserEdit === false" type="primary" @click="clickEdit">
                      编辑
                    </el-button>
                    <el-button v-if="isUserEdit === true" @click="clickCancel">
                      取消
                    </el-button>
                    <debounce-button v-if="isUserEdit === true" :loading="loading.submit" type="primary" @click="clickConfirm">
                      提交
                    </debounce-button>
                  </div>
                </template>
                <el-form ref="userForm" :model="userForm" :rules="userRules" label-width="180px" :label-suffix="constants.labelSuffix">
                  <div v-if="isUserEdit">
                    <el-col :span="12" :push="6">
                      <el-form-item label="用户名称">
                        <p>{{ utils.isEffectiveCommon(userForm.name) }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col />
                    <el-col :span="12" :push="6">
                      <el-form-item label="企业名称">
                        <p>{{ utils.isEffectiveCommon(userForm.companyName) }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col />
                    <el-col :span="12" :push="6">
                      <el-form-item label="企业信用代码">
                        <p>{{ utils.isEffectiveCommon(userForm.socialNo) }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col />
                    <el-col :span="12" :push="6">
                      <el-form-item label="手机号码">
                        <p>{{ utils.isEffectiveCommon(userForm.mobile) }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col />
                    <el-col :span="12" :push="6">
                      <el-form-item label="邮箱">
                        <el-input v-model="userForm.email" placeholder="请输入邮箱" />
                        <el-input v-show="false" />
                      </el-form-item>
                    </el-col>
                    <el-col />
                    <el-col :span="12" :push="6">
                      <el-form-item label="数字证书">
                        <p>
                          <debounce-button type="primary" @click="clickDialog">
                            更改
                          </debounce-button>
                        </p>
                        <p>
                          <a v-if="userForm.ukeyName" href="javascript:" class="text-btn" @click="utils.downloadP('other', userForm.ukeyUrl)">{{ userForm.ukeyName }}</a>
                        </p>
                      </el-form-item>
                    </el-col>
                  </div>
                  <div v-if="!isUserEdit">
                    <el-col :span="12" :push="6">
                      <el-form-item label="用户名称">
                        <p>{{ utils.isEffectiveCommon(userForm.name) }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col />
                    <el-col :span="12" :push="6">
                      <el-form-item label="企业名称">
                        <p>{{ utils.isEffectiveCommon(userForm.companyName) }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col />
                    <el-col :span="12" :push="6">
                      <el-form-item label="企业信用代码">
                        <p>{{ utils.isEffectiveCommon(userForm.socialNo) }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col />
                    <el-col :span="12" :push="6">
                      <el-form-item label="手机号码">
                        <p>
                          <span>{{ utils.isEffectiveCommon(userForm.mobile) }}</span>
                          <span style="margin-left: 20px;">
                            <a href="javascript:" class="text-btn" @click="dialogMobile">更改</a>
                          </span>
                        </p>
                      </el-form-item>
                    </el-col>
                    <el-col />
                    <el-col :span="12" :push="6">
                      <el-form-item label="邮箱">
                        <p>{{ utils.isEffectiveCommon(userForm.email) }}</p>
                      </el-form-item>
                    </el-col>
                    <el-col />
                    <el-col :span="12" :push="6">
                      <el-form-item label="数字证书">
                        <p><a v-if="userForm.ukeyName" href="javascript:;" @click="utils.downloadP('other', userForm.ukeyUrl)">{{ utils.isEffectiveCommon(userForm.ukeyName) }}</a></p>
                      </el-form-item>
                    </el-col>
                  </div>
                </el-form>
              </ics-page-inner>
              <div v-if="Number(defaultActiveIndex) === 2">
                <el-form ref="passwordForm" :model="passwordForm" :rules="passwordRules" label-width="180px" :label-suffix="constants.labelSuffix">
                  <div class="detail-area">
                    <div class="area-title">
                      <p class="title">
                        修改登陆密码
                      </p>
                      <div class="btn-inner">
                        <debounce-button :loading="loading.submit" type="button" class="el-button el-button--primary" @click="updatePassword">
                          提交
                        </debounce-button>
                      </div>
                    </div>
                    <div class="form-inner">
                      <el-col :span="12" :push="5">
                        <el-form-item label="旧密码" prop="oldPassword">
                          <el-input v-model="passwordForm.oldPassword" type="password" placeholder="请输入旧密码" />
                        </el-form-item>
                      </el-col>
                      <el-col />
                      <el-col :span="12" :push="5">
                        <el-form-item label="新密码" prop="newPassword">
                          <el-input v-model="passwordForm.newPassword" type="password" placeholder="请输入新密码" />
                        </el-form-item>
                      </el-col>
                      <el-col />
                      <el-col :span="12" :push="5">
                        <el-form-item label="新密码确认" prop="newPassAgain">
                          <el-input v-model="passwordForm.newPassAgain" type="password" placeholder="请输入新密码确认" />
                        </el-form-item>
                      </el-col>
                    </div>
                  </div>
                </el-form>
              </div>
              <div v-if="Number(defaultActiveIndex) === 3">
                <div class="partition-area">
                  <div class="el-card box-card is-always-shadow">
                    <div class="el-card__header">
                      <div class="clearfix">
                        <span class="box-card__title">控件下载</span>
                      </div>
                    </div>
                    <div class="el-card__body">
                      <ul style="list-style: none;">
                        <li>
                          <a href="/template/CryptoKit.CFCPNGM.exe">
                            <span>CryptoKit.CFCPNGM.exe</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <ics-dialog-inner width="45%" :visible.sync="dialog.mobile.visible" :title="dialog.mobile.title" @submit="validPhoneCode">
      <el-form ref="mobileForm" :model="mobileForm" :rules="mobileRules" label-width="120px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="" :show-header="true">
          <el-row :gutter="80" type="flex" justify="center" align="middle">
            <el-col :span="16">
              <el-form-item label="手机号码">
                <p>{{ utils.isEffectiveCommon(mobileForm.mobile) }}</p>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="80" type="flex" justify="center" align="middle">
            <el-col :span="16">
              <el-form-item prop="smsCode" label="验证码">
                <el-input v-model="mobileForm.smsCode" style="margin-left:-1px !important;width: 71%;" type="number" placeholder="请输入验证码" />
                <debounce-button v-if="showTime === false" :loading="loading.smsCode" style="padding: 10px 5px !important;vertical-align: middle; width: 92px;" @click="sendVerificationSms">
                  获取验证码
                </debounce-button>
                <el-button v-else style="padding: 9px 15px !important;vertical-align: middle;">
                  {{ time }}S后重发
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </ics-page-inner>
      </el-form>
    </ics-dialog-inner>
    <ics-upload-cer-inner :upload-dialog="dialog.uKey" @onSuccessFile="onSuccessFile" />
  </el-main>
</template>
<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsDialogInner from '@/components/dialog-inner'
import IcsUploadCerInner from '@/components/template/upload-cer-inner'
import IcsPageInner from '../components/page-inner'

export default {
  components: {IcsPageInner, IcsDialogInner, IcsUploadCerInner},
  mixins: [routeEnterMixin],
  data () {
    return {
      defaultActiveIndex: 1,
      userForm: {
        name: '',
        mobile: '',
        email: '',
        ukeyUrl: '',
        ukeyName: ''
      },
      passwordForm: {
        newPassword: '',
        oldPassword: '',
        newPassAgain: ''
      },
      mobileForm: {
        mobile: '',
        smsCode: ''
      },
      time: 300,
      showTime: false,
      dialog: {
        mobile: {
          visible: false,
          title: '验证手机号'
        },
        uKey: {
          visible: false,
          title: '上传数字证书'
        },
      },
      isUserEdit: false,
      el: '#tab',
      data: {
        curId: 0
      },
      rules: {},
      userRules: {},
      mobileRules: {},
      passwordRules: {
        oldPassword: this.changeRules({ name: '旧密码', required: true }),
        newPassword: [
          { required: true, validator: this.validateNewPassword, trigger: 'blur' }
        ],
        newPassAgain: [
          { required: true, validator: this.validateNewPassAgain, trigger: 'blur' }
        ]
      },
      loading: {
        detail: false,
        submit: false,
        smsCode: false
      }
    }
  },
  watch: {},
  created () {
    this.getDetail()
  },
  methods: {
    // 获取详情
    getDetail () {
      this.loading.detail = true
      this.api.system.employee.getPersonal().then(result => {
        const resData = result.data.data || {}
        this.userForm = this._.assign(this.userForm, resData)
      }).finally(e => {
        this.loading.detail = false
      })
    },
    dialogMobile () {
      if (!this.userForm.mobile) {
        this.$message.error('请先补充您的手机号码')
        return false
      }
      this.dialog.mobile.visible = true
      this.mobileForm.mobile = this.userForm.mobile
    },
    sendVerificationSms () {
      this.loading.smsCode = true
      const data = {
        mobile: this.mobileForm.mobile,
        type: 6,
        password: ''
      }
      this.api.open.sendSmsCode(data).then(result => {
        if (result.data.success === true) {
          this.$message({
            message: '发送成功',
            type: 'success'
          })
          this.showTime = true
          this.timer = setInterval(() => {
            if (this.time === 0) {
              clearInterval(this.timer)
              this.time = 300
              this.showTime = false
            } else {
              this.time--
            }
          }, 1000)
        } else {
          this.$message.error(result.data.message)
        }
      }).finally(() => {
        this.loading.smsCode = false
      })
    },
    validPhoneCode () {
      if (!this.mobileForm.smsCode) {
        this.$message.error('请输入验证码')
        return false
      }
      this.api.open.validPhoneCode(this.mobileForm.mobile, this.mobileForm.smsCode, 6).then(result => {
        if (result.data.success) {
          this.dialog.mobile.visible = false
          this.mobileForm.mobile = ''
          this.mobileForm.smsCode = ''
          this.$router.push({path: '/editMobile'})
        }
      }).finally(() => {
      })
    },
    clickEdit () {
      this.isUserEdit = true
      this.getDetail()
    },
    clickCancel () {
      this.isUserEdit = false
      this.userForm.email = ''
      this.userForm.ukeyUrl = ''
      this.userForm.ukeyName = ''
      this.getDetail()
    },
    clickConfirm () {
      const data = this._.cloneDeep(this.userForm)
      const info = {
        email: data.email,
        pin: data.pin,
        ukeyUrl: data.ukeyUrl,
        ukeyName: data.ukeyName
      }
      this.loading.submit = true
      this.api.system.employee.updatePersonalCenter(info).then(result => {
        if (result.data.data === true) {
          this.$message.success('修改成功')
          this.loading.submit = false
          this.clickCancel()
        } else {
          this.$message.success(result.data.message)
          this.loading.submit = false
        }
      }).finally(e => {
        this.loading.submit = false
      })
    },
    validateNewPassword  (rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else if (this.passwordForm.oldPassword === this.passwordForm.newPassword) {
        callback(new Error('新密码与旧密码一致、请重新输入！'))
      } else {
        callback()
      }
    },
    // 校验新密码
    validateNewPassAgain (rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入新密码'))
      } else if (this.passwordForm.newPassword && value !== this.passwordForm.newPassword) {
        callback(new Error('两次输入密码不一致、请重新输入！'))
      } else {
        callback()
      }
    },
    // 修改密码
    updatePassword () {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          this.loading.submit = true
          const data = {
            confirmNewPassword: this.passwordForm.newPassAgain,
            newPassword: this.passwordForm.newPassword,
            oldPassword: this.passwordForm.oldPassword
          }
          this.api.system.employee.updatePassword(data).then(result => {
            if (result.data.data === true) {
              this.$message.success('修改成功')
              this.logout()
            } else {
              this.$message.success(result.data.message)
            }
          }).finally(e => {
            this.loading.submit = false
          })
        } else {
          return false
        }
      })
    },
    logout () {
      this.api.base.logOut().then(result => {
        this.$store.commit('removeEmpInfo')
        window.location.href = process.env.VUE_APP_CONSOLE_DOMAIN + '/login'
      })
    },
    changeRules ({ name, required = false, max = 20, number = false }) {
      const arr = []
      if (required) {
        arr.push(this.rulesToolkit.rules.required({ name }))
      }
      if (number) {
        arr.push(this.rulesToolkit.rules.number({ name }))
      }
      arr.push(this.rulesToolkit.rules.range({ name, max }))
      return arr
    },
    selectMenu (index) {
      this.defaultActiveIndex = index
    },
    clickDialog () {
      this.dialog.uKey.visible = true
    },
    onSuccessFile(response) {
      const data = this._.cloneDeep(response.data)
      this.userForm.ukeyUrl = data.path
      this.userForm.ukeyName = data.name
    },
  }
}
</script>
<style lang="less" scoped>
.app-container {
  padding: 20px;
}
.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}
.el-menu {
  border-right: solid 1px #ffffff;
}
.el-menu-item {
  border-radius:30px 0 0 30px;
}
.el-menu-item.is-active {
  border-radius:30px 0 0 30px;
  color: #FFFFFF;
  background-color: rgb(102, 177, 255);
}
.el-menu-item:focus, .el-menu-item:hover {
  outline: 0;
  color: #FFFFFF;
  background-color: rgb(102, 177, 255);
}
.box-card .clearfix span{
  font-size: 18px;
  color: #000000;
}
.el-card__body {
  padding: 10px 20px;
  font-size: 14px;
}
.box-card ul li .load {
  float: right;
  cursor: pointer;
  text-decoration: underline;
}
</style>
