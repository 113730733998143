<template>
  <el-main>
    <ics-dialog-inner :loading="loading.submit" :visible.sync="uploadDialog.visible" :title="uploadDialog.title" submit-title="提交" cancel-title="关闭" class="form-dialog" width="40%" @submit="confirmUpdate" @close="close">
      <el-form ref="uploadForm" :model="formInfo" :rules="rules" label-width="140px" label-position="left" :label-suffix="constants.labelSuffix">
        <div class="upload-inner" style="width: 90%; top: 50%; left: 50%; margin-left: 30px; margin-top: 10px;">
          <fero-upload
            ref="upload"
            v-model="formInfo.fileList"
            :limit="limit"
            :action="uploadUrl"
            :headers="headers"
            drag
            :auto-upload="false"
            :before-upload="beforeUploadFile"
            :on-success="onSuccessFile"
            :on-preview="previewFile"
            :on-error="onErrorFile"
            :on-change="onChange"
            :on-remove="onChange"
            :on-exceed="onExceed"
          >
            <i class="el-icon-upload" />
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </fero-upload>
        </div>
      </el-form>
    </ics-dialog-inner>
  </el-main>
</template>

<script>
import IcsDialogInner from '@/components/dialog-inner'
import { routeEnterMixin } from '@/assets/js/mixins'
class FormProp {
  fileList = []
}
export default {
  name: 'IcsUploadCerInner',
  components: { IcsDialogInner },
  mixins: [routeEnterMixin],
  props: {
    uploadDialog: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data () {
    return {
      limit: 1,
      fileUploadUrl: process.env.VUE_APP_REQUEST_BASE_URL + '/channel/open/upload',
      uploadUrl: '',
      headers: {
        Token: this.$store.state.token
      },
      formInfo: new FormProp(),
      rules: {
        fileList: [
          { required: true, message: '请上传文件' }
        ]
      },
      loading: {
        submit: false
      }
    }
  },
  methods: {
    confirmUpdate () {
      if (this._.isEmpty(this.formInfo.fileList)) {
        this.$message.error('请上传附件')
        return false
      }
      this.$refs.uploadForm.validate().then(() => {
        this.uploadUrl = `${this.fileUploadUrl}?bucket=other`
        this.$nextTick(() => {
          this.loading.submit = true
          this.$refs.upload.submit()
        })
      })
    },
    onChange (file, fileList) {
      this.formInfo.fileList = fileList
      this.$refs.uploadForm.validateField('fileList')
    },
    onExceed () {
      this.$message.error(`一次只能上传${this.limit}个文件`)
    },
    beforeUploadFile (file) {
      const isLt = file.size / 1024 < 100 * 1000
      if (!isLt) {
        this.$message.error('上传文件最大为100M')
        this.loading.submit = false
        return isLt
      }
    },
    onSuccessFile (response, file, fileList) {
      this.$emit('onSuccessFile', response)
      this.$refs.upload.clearFiles()
      this.uploadDialog.visible = false
      this.loading.submit = false
    },
    onErrorFile (err, file, fileList) {
      this.$message.error(JSON.parse(err.message).message || '上传失败')
      this.formInfo.fileList = []
      this.loading.submit = false
      if (err.status === 401) {
        this.$store.commit('removeEmpInfo')
        window.location.href = process.env.VUE_APP_CONSOLE_DOMAIN + '/login?redirecturl=' + encodeURIComponent(window.location.href)
      }
    },
    previewFile (file) {
      this.utils.downloadFile(file.url, file.name)
    },
    close () {
      this.formInfo = new FormProp()
      this.$refs.upload.clearFiles()
      this.$refs.uploadForm.resetFields()
      this.loading.submit = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.dialog-footer{
  text-align: center;
}
</style>
